<template>
  <div class="cursor-pointer relative h-full" data-testid="context-card" @click="emitClick">
    <article
      class="h-full flex flex-col justify-between px-4 py-3 bg-white rounded-lg shadow hover:border-orange-400 border-2 border-gray-100 text-gray-800"
    >
      <div class="w-full flex justify-between overflow-hidden">
        <div class="flex w-1/2 items-start"><slot name="top-left"></slot></div>
        <div class="flex justify-end items-start"><slot name="top-right"></slot></div>
      </div>
      <div class="w-full flex justify-between">
        <div class="flex w-4/5 items-end"><slot name="bottom-left"></slot></div>
        <div class="flex w-1/5 justify-end items-end"><slot name="bottom-right"></slot></div>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: 'ContextCard',
  methods: {
    emitClick() {
      this.$emit('click');
    },
  },
};
</script>

<style scoped></style>
