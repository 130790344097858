<template>
  <button
    class="add-card flex flex-row-reverse items-center justify-center w-40 rounded-lg z-10 py-3 fixed h-auto bottom-0 mb-4 sm:mb-0 sm:w-auto sm:z-0 sm:flex-col sm:px-0 sm:relative shadow-md sm:shadow-none"
    @click="emitClick"
  >
    <slot></slot>
    <span
      class="rounded-full h-5 w-5 sm:h-7 sm:w-7 md:h-10 md:w-10 flex items-center justify-center px-2 md:px-4 text-center text-white text-2xl sm:text-2xl md:text-4xl leading-none"
    >
      +
    </span>
  </button>
</template>

<script>
export default {
  name: 'ContextButtonAdd',
  methods: {
    emitClick() {
      this.$emit('click');
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 639px) {
  .add-card {
    left: calc(50% - 75px);
  }
}
@media screen and (min-width: 640px) {
  .add-card {
    min-height: 10rem;
  }
}
</style>
