var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("context-board-filters", {
        attrs: { loading: _vm.loading, context: "user" },
        model: {
          value: _vm.apiParamsConfig,
          callback: function ($$v) {
            _vm.apiParamsConfig = $$v
          },
          expression: "apiParamsConfig",
        },
      }),
      _c(
        "div",
        {
          staticClass: "w-full pt-8 px-4",
          class: { "opacity-50": _vm.loading },
        },
        [
          _c(
            "div",
            { staticClass: "container flex justify-between m-auto" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "flex items-center text-orange-400 font-bold text-xl ml-1",
                },
                [_vm._v("Users")]
              ),
              _c("context-order", {
                attrs: { filters: _vm.defaultSortFilters },
                on: { change: _vm.callOrder, click: _vm.callOrder },
              }),
            ],
            1
          ),
        ]
      ),
      _c("user-card-list", {
        attrs: {
          users: _vm.users,
          loading: _vm.loading,
          "allowed-context-edit": _vm.allowedContextEdit,
        },
        on: {
          create: _vm.goToCreateUser,
          click: _vm.goToEditUser,
          edit: _vm.goToEditUser,
          delete: _vm.onDelete,
        },
      }),
      _c("delete-modal", {
        attrs: { open: _vm.entityToRemove ? true : false },
        on: {
          confirm: _vm.onDeleteUserConfirm,
          cancel: function ($event) {
            _vm.entityToRemove = null
          },
        },
        scopedSlots: _vm._u([
          {
            key: "description",
            fn: function () {
              return [_vm._v(" " + _vm._s(_vm.deleteDescription))]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.totalPages > 1
        ? _c(
            "div",
            {
              staticClass:
                "w-full flex justify-center items-center sm:mt-5 md:mt-10 mb-10 md:mb-20",
            },
            [
              _c("sun-pagination", {
                key: `pagination_${_vm.apiParamsConfig.page}_${_vm.totalPages}`,
                attrs: {
                  "total-pages": _vm.totalPages,
                  "current-page": _vm.apiParamsConfig.page,
                  "per-page": _vm.itemsPerPage,
                },
                on: { change: _vm.callPagination },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.loadingClient
        ? _c(
            "div",
            {
              staticClass:
                "absolute inset-0 h-screen flex items-center justify-center w-full",
            },
            [_c("loading")],
            1
          )
        : _vm._e(),
      _c("router-view", {
        on: { cancel: _vm.onCancelModal, save: _vm.onUserSave },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }