var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "context-card",
    { on: { click: _vm.emitClick } },
    [
      _c("template", { slot: "top-left" }, [
        _c(
          "h1",
          {
            staticClass:
              "text-gray-900 text-xl leading-none font-semibold text-left truncate w-full",
            attrs: { title: _vm.workspace.name },
          },
          [_vm._v(" " + _vm._s(_vm.workspace.name) + " ")]
        ),
      ]),
      _c(
        "template",
        { slot: "top-right" },
        [
          _c(
            "sun-badge",
            {
              staticClass:
                "px-2 text-xs whitespace-no-wrap truncate flex items-center",
              attrs: { color: "blue", "data-testid": "top-right" },
            },
            [_vm._v(" " + _vm._s(_vm.contextName) + " ")]
          ),
        ],
        1
      ),
      _c("template", { slot: "bottom-left" }, [
        _c(
          "div",
          {
            staticClass: "flex flex-col w-full",
            attrs: { "data-testid": "bottom-left" },
          },
          [
            _c("p", {
              staticClass:
                "text-xs md:text-sm text-gray-900 text-left font-semibold truncate w-full",
              attrs: { title: _vm.workspace.contactName },
              domProps: { textContent: _vm._s(_vm.workspace.contactName) },
            }),
            _c("p", {
              staticClass:
                "text-xs md:text-sm text-gray-500 text-left truncate w-full",
              attrs: { title: _vm.workspace.email },
              domProps: { textContent: _vm._s(_vm.workspace.email) },
            }),
          ]
        ),
      ]),
      _c("template", { slot: "bottom-right" }, [
        _vm.allowedContextEdit
          ? _c(
              "div",
              {
                staticClass: "relative",
                attrs: { "data-testid": "bottom-right" },
              },
              [
                _c("asterix-dropdown", {
                  attrs: {
                    options: _vm.actions,
                    "option-list-position": "left",
                  },
                  on: { change: _vm.actionClick },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "icon",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex h-full items-center justify-end p-1",
                              },
                              [
                                _c("menu-dots-svg", {
                                  staticClass: "text-gray-500 h-5",
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    1090316411
                  ),
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }