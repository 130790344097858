var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "cursor-pointer relative h-full",
      attrs: { "data-testid": "context-card" },
      on: { click: _vm.emitClick },
    },
    [
      _c(
        "article",
        {
          staticClass:
            "h-full flex flex-col justify-between px-4 py-3 bg-white rounded-lg shadow hover:border-orange-400 border-2 border-gray-100 text-gray-800",
        },
        [
          _c(
            "div",
            { staticClass: "w-full flex justify-between overflow-hidden" },
            [
              _c(
                "div",
                { staticClass: "flex w-1/2 items-start" },
                [_vm._t("top-left")],
                2
              ),
              _c(
                "div",
                { staticClass: "flex justify-end items-start" },
                [_vm._t("top-right")],
                2
              ),
            ]
          ),
          _c("div", { staticClass: "w-full flex justify-between" }, [
            _c(
              "div",
              { staticClass: "flex w-4/5 items-end" },
              [_vm._t("bottom-left")],
              2
            ),
            _c(
              "div",
              { staticClass: "flex w-1/5 justify-end items-end" },
              [_vm._t("bottom-right")],
              2
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }