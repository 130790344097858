<template>
  <div v-if="filterSelected" class="relative inline-flex flex-row justify-end items-start pb-1">
    <asterix-dropdown
      :button-classes="
        isDropdownVisible
          ? 'flex flex-1 justify-between items-center relative cursor-pointer wrapper-min-width bg-gray-800 rounded-md px-3 py-2'
          : 'flex flex-1 justify-between items-center relative cursor-pointer wrapper-min-width rounded-md px-3 py-2'
      "
      :options="filtersArray"
      :value="filterSelected"
      @change="clickFilterList"
      @visible="dropdownVisible"
    >
      <template #default>
        <span class="leading-none text-sm mr-1 flex items-center">
          <!-- <filter-group-svg class="w-4 mr-2 text-gray-500" /> -->
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 pt-1" fill="none" viewBox="0 0 24 24" stroke="gray">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M3 4h13M3 8h9m-9 4h9m5-4v12m0 0l-4-4m4 4l4-4"
            />
          </svg>
          <span class="font-bold text-orange-400">{{ filterSelected.value }}</span>
        </span>
      </template>
      <template #icon="state">
        <span class="transition-100" :class="!state.visible ? 'rotate-180' : ''">
          <up-svg class="w-2 h-2" :class="state.disabled ? 'text-gray-400' : 'text-gray-500'" />
        </span>
      </template>
      <template #option="slotProps">
        <div
          class="w-full px-3 text-xs font-bold text-gray-500 hover:text-white hover:bg-gray-700 rounded-full whitespace-no-wrap"
          @click="clickFilterList(slotProps.option.option)"
        >
          {{ slotProps.option.option.value }}
        </div>
      </template>
    </asterix-dropdown>
  </div>
</template>

<script>
import AsterixDropdown from '@/components/molecules/shared/AsterixDropdown.vue';
import FILTERS from './config';

export default {
  name: 'ContextOrder',
  components: {
    UpSvg: () => import('../../icons/UpSvg.vue'),
    // FilterGroupSvg: () => import('../../icons/FilterGroupSvg.vue'),
    AsterixDropdown,
  },
  props: {
    filters: {
      type: Array,
      default: () => FILTERS.defaultFilters,
    },
  },
  data() {
    return {
      orderObject: {},
      filtersArray: this.filters,
      defaultOrderText: FILTERS.defaultOrderText,
      orderValue: FILTERS.defaultOrder,
      isDropdownVisible: false,
    };
  },
  computed: {
    filterSelected: {
      get() {
        let filterSelected;
        this.filtersArray.forEach(filter => {
          if (filter.selected) {
            filterSelected = filter;
          }
        });
        return filterSelected;
      },
      set(index) {
        this.resetFiltersArray();
        this.filtersArray[index].selected = true;
      },
    },
  },
  beforeMount() {
    this.checkFilters();
  },
  methods: {
    dropdownVisible(payload) {
      this.isDropdownVisible = payload;
    },
    checkFilters() {
      if (this.filters[0].selected === undefined) {
        this.filterSelected = 0;
      } else {
        const ind = this.filters.findIndex(type => type.selected === true);
        if (ind !== -1) this.filterSelected = ind;
      }
    },
    resetFiltersArray() {
      this.filtersArray.map(item => (item.selected = false));
    },
    getOrderObject(f, o) {
      return {
        filter: f,
        order: o,
      };
    },
    clickFilterList(optionSelected) {
      this.filterSelected = this.filtersArray.indexOf(optionSelected);
      this.emitFilterChange();
    },
    emitFilterChange() {
      const ordOb = this.getOrderObject(this.filterSelected.name, this.orderValue);
      this.$emit('change', ordOb);
    },
  },
};
</script>

<style>
.wrapper-min-width {
  min-width: 10rem;
}
.filters-list {
  position: absolute;
  top: 30px;
  right: 0;
  min-width: 75px;
}
.arrow-fix {
  width: 0.5rem;
  display: inline-flex;
  line-height: normal;
}
</style>
