var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-full px-4 py-5 bg-white" }, [
    _c(
      "div",
      {
        staticClass: "container mx-auto flex flex-col md:flex-row",
        class:
          _vm.context === "user"
            ? "flex flex-col md:flex md:flex-row md:items-center md:justify-between"
            : "flex items-center justify-between",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "flex justify-start w-full width-filters overflow-auto sm::overflow-hidden",
          },
          [
            _c(
              "sun-button",
              {
                staticClass:
                  "w-auto px-4 mb-1 mr-2 text-xs whitespace-no-wrap rounded-full custom-p-1",
                class: _vm.isShowAllSelected
                  ? "text-white bg-orange-600"
                  : "text-gray-600 bg-transparent border border-gray-400",
                attrs: {
                  variant: _vm.isShowAllSelected ? "pill" : "outline",
                  color: _vm.isShowAllSelected ? "orange" : "gray",
                  "data-testid": "context-filter-ALL",
                  disabled: _vm.loading,
                },
                on: { click: _vm.showAllContexts },
              },
              [_vm._v(" ALL ")]
            ),
            _vm._l(_vm.availableContexts, function (type) {
              return _c(
                "sun-button",
                {
                  key: `type-${type.id}`,
                  staticClass:
                    "w-auto px-4 mb-1 mr-2 text-xs whitespace-no-wrap rounded-full custom-p-1",
                  attrs: {
                    "data-testid": `context-filter-${type.id}`,
                    variant: type.selected ? "pill" : "outline",
                    color: _vm.getButtonClasses(type),
                    disabled: _vm.loading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.clickFilterContextType(type)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(type.name) + " ")]
              )
            }),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "flex justify-end w-full pt-4 md:w-2/4 md:pt-0" },
          [
            _vm.context === "user"
              ? _c(
                  "div",
                  { staticClass: "relative flex w-full pr-2" },
                  [
                    _c("sun-select", {
                      attrs: {
                        options: _vm.userRolesOptions,
                        "track-by": "name",
                        label: "name",
                        placeholder: "Select a role",
                      },
                      on: { change: _vm.changeRoleSelect },
                      model: {
                        value: _vm.userRoleValue,
                        callback: function ($$v) {
                          _vm.userRoleValue = $$v
                        },
                        expression: "userRoleValue",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "relative w-full pl-1",
                class: { "lg:w-1/2": _vm.context !== "user" },
              },
              [
                _c("sun-search-input", {
                  attrs: {
                    id: "searchContext",
                    value: _vm.filterValue.name,
                    placeholder: _vm.searchPlaceholder,
                    disabled: _vm.loading,
                  },
                  on: { search: _vm.onQuickFiltersSearch },
                }),
              ],
              1
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }