<template>
  <div>
    <context-board-filters v-model="apiParamsConfig" :loading="loading" context="workspace" />
    <div :class="{ 'opacity-50': loading }" class="w-full px-4 pt-8">
      <div class="container flex justify-between m-auto">
        <div class="flex items-center ml-1 text-xl font-bold text-orange-400">Workspaces</div>
        <context-order :filters="defaultSortFilters" @change="callOrder" @click="callOrder" />
      </div>
    </div>
    <workspace-card-list
      :workspaces="workspaces"
      :loading="loading"
      :allowed-context-edit="allowedContextEdit"
      @click="goToWorkspace"
      @edit="goToEditWorkspace"
      @delete="onDeleteWorkspace"
      @create="goToCreateWorkspace"
    />
    <div v-if="totalPages > 1" class="flex items-center justify-center w-full mb-10 sm:mt-5 md:mt-10">
      <sun-pagination
        :key="`pagination_${apiParamsConfig.page}_${totalPages}`"
        :total-pages="totalPages"
        :current-page="apiParamsConfig.page"
        :per-page="itemsPerPage"
        @change="callPagination"
      />
    </div>

    <delete-modal
      :open="workspaceToRemove ? true : false"
      @confirm="onDeleteWorkspaceConfirm"
      @cancel="workspaceToRemove = null"
    >
      <template #description>{{ deleteDescription }}</template>
    </delete-modal>
    <div v-if="loadingClient" class="absolute inset-0 flex items-center justify-center w-full h-screen">
      <loading />
    </div>

    <router-view @cancel="onCancelModal" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { USER } from '@/store/modules/auth/keys';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { PARAM_LIST, SAVE_PARAMS, RESET_CONTEXTBOARD } from '@/store/modules/contextBoard/keys';
import { RESET_CONTEXT, SET_ACTIVE_CLIENT } from '@/store/modules/context/keys';
import { getClients } from '@/services/modules/Core/client';
import { Toast } from '@/model/shared/Toast';
import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';
import { CONTEXT_BOARD_BASE } from '@/model/shared/constants';
import { deleteClient as deleteThirdPartyClient } from '@/services/modules/ThirdParty/client';
import { deleteClient as deleteEcommerceClient } from '@/services/modules/ecommerce/client';
import { deleteClient as deleteDashboardSSPClient } from '@/services/modules/dashboardSSP/client';
import { deleteClient as deleteSocialAudienceClient } from '@/services/modules/socialAudience/client';
import { deleteClient as deleteSunStudioClient } from '@/services/modules/sunStudio/client';
import WorkspaceCardList from '@/components/organisms/modules/core/workspace/WorkspaceCardList';
import ContextBoardFilters from '@/components/organisms/modules/core/ContextBoardFilters';
import ContextOrder from '@/components/atoms/ContextOrder/ContextOrder';

import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import Loading from '@/components/atoms/LogoLoading';
import CONFIG from '../config';
import { queryParamsMixin } from '@/mixins/common/queryParamsMixin';
import { deepClone } from '@/utils/deepClone';
import apiRequest from '@/utils/apiRequest';
import DeleteModal from '@/components/organisms/shared/DeleteModal';
import { sunStudio } from '@/router/private/modules/sunStudio';
import getDefaultRouterNameByUser from '@/model/shared/context/getDefaultRouterByUser';

export default {
  name: 'ContextBoardWorkspaces',
  components: {
    WorkspaceCardList,
    Loading,
    ContextBoardFilters,
    ContextOrder,
    DeleteModal,
  },
  mixins: [queryParamsMixin],
  data() {
    return {
      totalPages: CONFIG.totalPages,
      itemsPerPage: CONFIG.itemsPerPage,
      workspaces: [],
      params: null,
      loading: true,
      loadingClient: false,
      workspaceToRemove: null,
      defaultSortValue: 'name',
      apiParamsConfig: {
        name: null,
        role: null,
        type: null,
        page: 1,
        sort: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      user: USER,
      storedParams: PARAM_LIST,
    }),
    deleteDescription() {
      return `You are going to remove ${this.workspaceToRemove.name}. This action can not be undone.`;
    },
    allowedContextEdit() {
      return this.user?.contextRoles?.find(
        contextRole => contextRole.context === CONTEXTS.CORE.id && contextRole.role === ROLES.ADMIN.id
      );
    },
    defaultSortFilters() {
      return CONFIG.defaultFilters.map(sortOption => {
        sortOption.name === this.apiParamsConfig.sort ? (sortOption.selected = true) : (sortOption.selected = false);
        return sortOption;
      });
    },
  },
  watch: {
    apiParamsConfig: {
      deep: true,
      handler() {
        const state = deepClone(this.apiParamsConfig);
        this.setStoreParams(state);
        this.filtersChanges();
      },
    },
  },
  created() {
    this.resetContext();
    this.paramsLoadFromQueryString();
  },
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
      resetContext: RESET_CONTEXT,
      setActiveClient: SET_ACTIVE_CLIENT,
      resetContextBoard: RESET_CONTEXTBOARD,
      setStoreParams: SAVE_PARAMS,
    }),
    async filtersChanges() {
      this.removeQueryParams(['name', 'type', 'sort', 'page']);
      this.params = new QueryParamsBuilder(this.apiParamsConfig.page, this.itemsPerPage);
      Object.entries(this.apiParamsConfig).forEach(keyValue => {
        const [key, value] = keyValue;
        switch (key) {
          case 'name':
            if (value) {
              this.addQueryParams({ name: value });
              this.params.addFilter('name', value);
            }
            break;
          case 'type':
            if (value) {
              this.addQueryParams({ type: value });
              this.params.addFilter('type', value);
            }
            break;
          case 'sort':
            this.addQueryParams({ sort: value });
            this.params.addSort(value);
            break;
          case 'page':
            if (value !== 1) {
              this.addQueryParams({ page: value });
            }
            break;
          default:
            break;
        }
      });
      await this.queryParamsRouterReplace();
      this.getWorkspacesCore();
    },
    callOrder({ filter }) {
      this.apiParamsConfig.sort = filter;
    },
    callPagination(pageObject = { page: 1 }) {
      this.apiParamsConfig.page = pageObject.page;
    },
    paramsLoadFromQueryString() {
      const filtersToSave = {};
      Object.entries(this.queryParams).forEach(keyValue => {
        const [key, value] = keyValue;
        this.$set(filtersToSave, key, value);
      });
      const { name, type, sort, page } = Object.keys(filtersToSave).length ? filtersToSave : this.storedParams;
      this.apiParamsConfig.name = name;
      this.apiParamsConfig.type = type;
      this.apiParamsConfig.sort = sort || this.defaultSortValue;
      this.apiParamsConfig.page = parseInt(page) || 1;
    },

    async getWorkspacesCore() {
      this.loading = true;
      await apiRequest(async () => {
        const { data, meta } = await getClients(this.params);
        this.totalPages = meta.totalPages;
        this.workspaces = data;
      }).catch(() => {
        const toast = Toast.error(`We can't load workspaces.`, `We couldn't get workspaces list. Try again later.`);
        this.createToast(toast);
      });
      this.loading = false;
    },
    goToEditWorkspace(workspace) {
      const urlName = `${CONTEXT_BOARD_BASE}-edit-workspace`;
      const params = { clientId: workspace.id };

      this.$router.push({
        name: urlName,
        params,
      });
    },
    async goToCreateWorkspace() {
      await this.$router.push({
        name: `${CONTEXT_BOARD_BASE}-new-workspace`,
      });
    },
    async goToWorkspace(workspace) {
      if (workspace.type === CONTEXTS.SUNSTUDIO.id) {
        window.open(`${sunStudio.path}/${workspace.id}`, '_blank');
        return;
      }
      this.loadingClient = true;
      try {
        await this.setActiveClient(workspace);
        await this.$router.push({
          name: getDefaultRouterNameByUser(CONTEXTS[workspace.type], this.user),
          params: { clientId: workspace.id },
        });
      } catch (error) {
        const toast = Toast.error(`Cannot load workspace.`, `${workspace.name} could not be found.`);
        this.createToast(toast);
        this.loadingClient = false;
      }
    },
    async onCancelModal() {
      await this.$router.push({
        name: `${CONTEXT_BOARD_BASE}-workspaces`,
      });
      await this.queryParamsRouterReplace();
    },
    async onDeleteWorkspaceConfirm() {
      try {
        switch (this.workspaceToRemove.type) {
          case CONTEXTS.THIRD_PARTY.id:
            await deleteThirdPartyClient(this.workspaceToRemove);
            break;
          case CONTEXTS.ECOMMERCE.id:
            await deleteEcommerceClient(this.workspaceToRemove);
            break;
          case CONTEXTS.DASHBOARD_SSP.id:
            await deleteDashboardSSPClient(this.workspaceToRemove);
            break;
          case CONTEXTS.SOCIAL_AUDIENCE.id:
            await deleteSocialAudienceClient(this.workspaceToRemove);
            break;
          case CONTEXTS.SUNSTUDIO.id:
            await deleteSunStudioClient(this.workspaceToRemove);
            break;
        }
        this.createToast(
          Toast.success('Workspace deleted', `Workspace ${this.workspaceToRemove.name} was deleted successfully.`)
        );
        await this.getWorkspacesCore();
        this.workspaceToRemove = null;
      } catch (error) {
        this.createToast(Toast.error('Workspace not deleted', error.message));
      }
    },
    onDeleteWorkspace(workspace) {
      this.workspaceToRemove = workspace;
    },
  },
};
</script>
