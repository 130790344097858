<template>
  <div>
    <context-card v-if="user" @click="emitClick">
      <template slot="top-left">
        <h1 :title="user.name" class="text-gray-900 text-xl leading-none font-semibold text-left truncate w-full">
          {{ user.name }}
        </h1>
      </template>
      <template slot="top-right">
        <div
          v-if="user.contextRoles.length > 0"
          class="badge-wrapper flex flex-row justify-end align-center overflow-hidden overflow-y-auto sun-badge-min-width"
        >
          <sun-badge color="blue" class="px-2 text-xs whitespace-no-wrap truncate">
            {{ getContextName(reversedUserRoles[0].context) | capitalize }}
          </sun-badge>
          <template v-if="user.contextRoles.length > 1">
            <span class="badge-circle relative flex items-center rounded-full text-xs bg-blue-300 -ml-2">
              +{{ user.contextRoles.length - 1 }}
            </span>
            <span class="context-roles-list absolute p-2 z-10 bg-white border border-gray-300 rounded shadow-md">
              <div
                v-for="(userRole, index) in reversedUserRoles"
                :key="index"
                class="w-full flex flex-row justify-between items-center py-1"
              >
                <span class="px-1 mr-6 text-sm text-gray-900 whitespace-no-wrap">
                  {{ userRole.role | capitalize }}
                </span>
                <sun-badge color="blue" class="px-2 text-xs whitespace-no-wrap truncate">
                  {{ getContextName(userRole.context) }}
                </sun-badge>
              </div>
            </span>
          </template>
        </div>
      </template>
      <template slot="bottom-left">
        <p :title="user.email" class="text-xs md:text-sm text-gray-500 text-left truncate">
          {{ user.email }}
        </p>
      </template>
      <template slot="bottom-right">
        <div v-if="allowedContextEdit" class="relative">
          <asterix-dropdown :options="actions" option-list-position="left" @change="emitAction">
            <template #icon>
              <div class="flex h-full items-center justify-end p-1">
                <menu-dots-svg class="text-gray-500 h-5" />
              </div>
            </template>
          </asterix-dropdown>
        </div>
      </template>
    </context-card>
  </div>
</template>

<script>
import AsterixDropdown from '@/components/molecules/shared/AsterixDropdown';
import ContextCard from '@/components/atoms/ContextCard/ContextCard';
import { CONTEXTS } from '@/model/shared/contexts';

export default {
  name: 'ContextCardUser',
  components: {
    ContextCard,
    AsterixDropdown,
    MenuDotsSvg: () => import('@/components/icons/MenuDotsSvg'),
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    allowedContextEdit: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      seeActions: false,
      actions: [
        { id: 'Edit', name: 'Edit', value: 'edit' },
        { id: 'Delete', name: 'Delete', value: 'delete' },
      ],
    };
  },
  computed: {
    reversedUserRoles() {
      const roles = [...this.user?.contextRoles];
      return roles.reverse();
    },
  },
  methods: {
    getContextName(context) {
      return CONTEXTS[context].name;
    },
    toggleActions() {
      this.seeActions = !this.seeActions;
    },
    emitAction(action) {
      switch (action.id) {
        case 'Edit':
          this.emitEdit();
          break;
        case 'Delete':
          this.emitDelete();
          break;
      }
    },
    emitClick() {
      this.$emit('click', this.user.id);
    },
    emitEdit() {
      this.$emit('edit', this.user.id);
      this.toggleActions();
    },
    emitDelete() {
      this.$emit('delete', this.user);
    },
  },
};
</script>

<style scoped>
.badge-circle {
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  height: 25px;
  width: 25px;
}
.badge-circle:hover + .context-roles-list {
  display: inline-block;
  right: 10px;
  top: 40px;
}
.context-roles-list {
  display: none;
  max-width: 300px;
}
.context-roles-list .sun-badge-item:first-child {
  display: none;
}
.context-card-actions {
  left: 30px;
  top: 0px;
}
</style>
