<template>
  <div class="w-full px-4 py-5 bg-white">
    <div
      class="container mx-auto flex flex-col md:flex-row"
      :class="
        context === 'user'
          ? 'flex flex-col md:flex md:flex-row md:items-center md:justify-between'
          : 'flex items-center justify-between'
      "
    >
      <div class="flex justify-start w-full width-filters overflow-auto sm::overflow-hidden">
        <sun-button
          :variant="isShowAllSelected ? 'pill' : 'outline'"
          :color="isShowAllSelected ? 'orange' : 'gray'"
          class="w-auto px-4 mb-1 mr-2 text-xs whitespace-no-wrap rounded-full custom-p-1"
          :class="
            isShowAllSelected ? 'text-white bg-orange-600' : 'text-gray-600 bg-transparent border border-gray-400'
          "
          data-testid="context-filter-ALL"
          :disabled="loading"
          @click="showAllContexts"
        >
          ALL
        </sun-button>
        <sun-button
          v-for="type in availableContexts"
          :key="`type-${type.id}`"
          :data-testid="`context-filter-${type.id}`"
          :variant="type.selected ? 'pill' : 'outline'"
          :color="getButtonClasses(type)"
          class="w-auto px-4 mb-1 mr-2 text-xs whitespace-no-wrap rounded-full custom-p-1"
          :disabled="loading"
          @click="clickFilterContextType(type)"
        >
          {{ type.name }}
        </sun-button>
      </div>
      <div class="flex justify-end w-full pt-4 md:w-2/4 md:pt-0">
        <div v-if="context === 'user'" class="relative flex w-full pr-2">
          <sun-select
            v-model="userRoleValue"
            :options="userRolesOptions"
            track-by="name"
            label="name"
            placeholder="Select a role"
            @change="changeRoleSelect"
          />
        </div>
        <div class="relative w-full pl-1" :class="{ 'lg:w-1/2': context !== 'user' }">
          <sun-search-input
            id="searchContext"
            :value="filterValue.name"
            :placeholder="searchPlaceholder"
            :disabled="loading"
            @search="onQuickFiltersSearch"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { AVAILABLE_CONTEXTS, CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';
import { deepClone } from '@/utils/deepClone';
import { AUTH_USER_AVAILABLE_CONTEXTS } from '@/store/modules/auth/keys';

export default {
  name: 'ContextBoardFilters',
  props: {
    loading: {
      type: Boolean,
      default: () => true,
    },
    context: {
      type: String,
      default: () => {},
    },
    value: {
      type: Object,
      default: () => {},
    },
  },
  data: vm => ({
    availableContexts: [],
    userRoleValue: null,
    filterValue: vm.value,
  }),
  computed: {
    ...mapGetters({
      authUserAvailableContexts: AUTH_USER_AVAILABLE_CONTEXTS,
    }),
    isShowAllSelected() {
      return this.selectedContexts.length === 0 || this.selectedContexts.length === this.availableContexts.length;
    },
    selectedContexts() {
      return this.availableContexts.filter(type => type.selected);
    },
    searchPlaceholder() {
      return `Search ${this.context}...`;
    },
    userRolePlaceholder() {
      return this.userRoleValue ? this.userRoleValue.name : 'Select role';
    },
    userRolesOptions() {
      const rolesModel = [];
      for (const prop in ROLES) {
        const roleObject = {
          name: ROLES[prop].name,
          value: ROLES[prop].id,
        };
        rolesModel.push(roleObject);
      }
      return rolesModel;
    },
    getUserRoleByName() {
      let initialRole = null;
      Object.values(ROLES).forEach(rol => {
        if (rol.id === this.filterValue.role) {
          initialRole = {
            name: rol.name,
            value: rol.id,
          };
        }
      });
      return initialRole;
    },
  },
  beforeMount() {
    this.setData();
    this.checkContextSelected();
  },
  methods: {
    setData() {
      this.userRoleValue = this.getUserRoleByName;
    },
    checkContextSelected() {
      const contexts = deepClone(
        Object.values(AVAILABLE_CONTEXTS).filter(
          context => context.id !== CONTEXTS.CORE.id && this.authUserAvailableContexts.includes(context.id)
        )
      );
      if (this.value.type) {
        contexts.forEach(context => (context.selected = this.value.type.includes(context.id)));
      }
      this.availableContexts = contexts;
    },
    getButtonClasses(type) {
      if (this.isShowAllSelected) {
        return 'gray';
      }
      if (type.selected) {
        return 'orange';
      }
      return 'gray';
    },
    clickFilterContextType(context) {
      this.$set(context, 'selected', !context?.selected);
      this.emitContextSelected();
    },
    onQuickFiltersSearch(value) {
      this.filterValue.name = value;
      this.filterValue.page = 1;
      this.$emit('input', this.filterValue);
    },
    changeRoleSelect(param) {
      this.filterValue.role = param.value ? param.value.value : '';
      this.filterValue.page = 1;
      this.$emit('input', this.filterValue);
    },
    showAllContexts() {
      this.resetTypesSelected();
      this.emitContextSelected();
    },
    resetTypesSelected() {
      this.availableContexts.forEach(item => this.$set(item, 'selected', false));
    },
    emitContextSelected() {
      this.filterValue.type = '';
      this.filterValue.type = this.selectedContexts.map(context => context.id).join(',');

      this.filterValue.page = 1;
      this.$emit('input', this.filterValue);
    },
  },
};
</script>

<style scoped>
.filter-search-svg-absolute {
  display: inline-block;
  position: absolute;
  top: 8px;
  right: 8px;
}

@media (min-width: 640px) {
  .width-filters {
    min-width: max-content;
  }
}
</style>
