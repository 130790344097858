const FILTERS = {
  defaultFilters: [
    { id: 'name', name: 'name', value: 'Name', selected: false },
    { id: 'email', name: 'email', value: 'Email', selected: false },
  ],
  defaultClasses: 'relative sm:absolute sm:right-0 sm:top-0 sm:z-11',
  defaultOrder: 'ASC',
  defaultOrderText: 'Order by',
};

export default FILTERS;
