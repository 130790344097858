<template>
  <div>
    <context-board-filters v-model="apiParamsConfig" :loading="loading" context="user" />
    <div :class="{ 'opacity-50': loading }" class="w-full pt-8 px-4">
      <div class="container flex justify-between m-auto">
        <div class="flex items-center text-orange-400 font-bold text-xl ml-1">Users</div>
        <context-order :filters="defaultSortFilters" @change="callOrder" @click="callOrder" />
      </div>
    </div>
    <user-card-list
      :users="users"
      :loading="loading"
      :allowed-context-edit="allowedContextEdit"
      @create="goToCreateUser"
      @click="goToEditUser"
      @edit="goToEditUser"
      @delete="onDelete"
    />

    <delete-modal :open="entityToRemove ? true : false" @confirm="onDeleteUserConfirm" @cancel="entityToRemove = null">
      <template #description> {{ deleteDescription }}</template>
    </delete-modal>

    <div v-if="totalPages > 1" class="w-full flex justify-center items-center sm:mt-5 md:mt-10 mb-10 md:mb-20">
      <sun-pagination
        :key="`pagination_${apiParamsConfig.page}_${totalPages}`"
        :total-pages="totalPages"
        :current-page="apiParamsConfig.page"
        :per-page="itemsPerPage"
        @change="callPagination"
      />
    </div>
    <div v-if="loadingClient" class="absolute inset-0 h-screen flex items-center justify-center w-full">
      <loading />
    </div>
    <router-view @cancel="onCancelModal" @save="onUserSave" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Toast } from '@/model/shared/Toast';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { USER } from '@/store/modules/auth/keys';
import { PARAM_LIST, SAVE_PARAMS, RESET_CONTEXTBOARD } from '@/store/modules/contextBoard/keys';
import { RESET_CONTEXT } from '@/store/modules/context/keys';
import { CONTEXT_BOARD_BASE } from '@/model/shared/constants';
import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';
import { deleteUser, getUsers } from '@/services/modules/Core/user';
import { queryParamsMixin } from '@/mixins/common/queryParamsMixin';
import ContextBoardFilters from '@/components/organisms/modules/core/ContextBoardFilters';
import UserCardList from '@/components/organisms/modules/core/user/userCardList';
import ContextOrder from '@/components/atoms/ContextOrder/ContextOrder';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import Loading from '@/components/atoms/LogoLoading';
import CONFIG from '../config';
import { deepClone } from '@/utils/deepClone';
import { HTTPStatusCode } from '@/model/shared/HTTPStatusCode';
import DeleteModal from '@/components/organisms/shared/DeleteModal';

export default {
  name: 'ContextBoardUsers',
  components: {
    UserCardList,
    Loading,
    ContextBoardFilters,
    ContextOrder,
    DeleteModal,
  },
  mixins: [queryParamsMixin],
  data() {
    return {
      totalPages: CONFIG.totalPages,
      itemsPerPage: CONFIG.itemsPerPage,
      users: [],
      params: null,
      loading: true,
      loadingClient: false,
      defaultSortValue: 'name',
      entityToRemove: null,
      apiParamsConfig: {
        name: null,
        role: null,
        type: null,
        page: 1,
        sort: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      user: USER,
      storedParams: PARAM_LIST,
    }),
    deleteDescription() {
      return `You are going to remove ${this.entityToRemove.name}. This action can not be undone.`;
    },
    allowedContextEdit() {
      return this.user?.contextRoles?.find(
        contextRole => contextRole.context === CONTEXTS.CORE.id && contextRole.role === ROLES.ADMIN.id
      );
    },
    defaultSortFilters() {
      return CONFIG.defaultFilters.map(sortOption => {
        sortOption.name === this.apiParamsConfig.sort ? (sortOption.selected = true) : (sortOption.selected = false);
        return sortOption;
      });
    },
  },
  watch: {
    apiParamsConfig: {
      deep: true,
      async handler() {
        const state = deepClone(this.apiParamsConfig);
        this.setStoreParams(state);
        await this.filtersChanges();
      },
    },
  },
  created() {
    this.resetContext();
    this.paramsLoadFromQueryString();
  },
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
      resetContext: RESET_CONTEXT,
      resetContextBoard: RESET_CONTEXTBOARD,
      setStoreParams: SAVE_PARAMS,
    }),
    async onDeleteUserConfirm() {
      try {
        await deleteUser(this.entityToRemove);
        await this.getUsersCore();
        this.createToast(Toast.success('User deleted', `User ${this.entityToRemove.name} was deleted successfully.`));
        this.entityToRemove = null;
      } catch (error) {
        this.createToast(Toast.error('User not deleted', error.message));
      }
    },
    onDelete(entity) {
      this.entityToRemove = entity;
    },
    async filtersChanges() {
      this.removeQueryParams(['name', 'type', 'role', 'sort', 'page']);
      this.params = new QueryParamsBuilder(this.apiParamsConfig.page, this.itemsPerPage);
      Object.entries(this.apiParamsConfig).forEach(keyValue => {
        const [key, value] = keyValue;
        switch (key) {
          case 'name':
            if (value) {
              this.addQueryParams({ name: value });
              this.params.addFilter('name', value);
            }
            break;
          case 'type':
            if (value) {
              this.addQueryParams({ type: value });
              this.params.addFilter('contextRole.context', value);
            }
            break;
          case 'role':
            if (value) {
              this.addQueryParams({ role: value });
              this.params.addFilter('contextRole.role', value);
            }
            break;
          case 'sort':
            this.addQueryParams({ sort: value });
            this.params.addSort(value);
            break;
          case 'page':
            if (value !== 1) {
              this.addQueryParams({ page: value });
            }
            break;
          default:
            break;
        }
      });
      await this.queryParamsRouterReplace();
      await this.getUsersCore();
    },
    callOrder({ filter }) {
      this.apiParamsConfig.sort = filter;
    },
    callPagination(pageObject = { page: 1 }) {
      this.apiParamsConfig.page = pageObject.page;
    },
    paramsLoadFromQueryString() {
      const filtersToSave = {};
      Object.entries(this.queryParams).forEach(keyValue => {
        const [key, value] = keyValue;
        this.$set(filtersToSave, key, value);
      });
      if (Object.keys(filtersToSave).length > 0) {
        this.apiParamsConfig.name = filtersToSave.name;
        this.apiParamsConfig.type = filtersToSave.type;
        this.apiParamsConfig.role = filtersToSave.role;
        this.apiParamsConfig.sort = filtersToSave.sort || this.defaultSortValue;
        this.apiParamsConfig.page = parseInt(filtersToSave.page) || 1;
      } else {
        this.apiParamsConfig.name = this.storedParams.name;
        this.apiParamsConfig.type = this.storedParams.type;
        this.apiParamsConfig.role = this.storedParams.role;
        this.apiParamsConfig.sort = this.storedParams.sort || this.defaultSortValue;
        this.apiParamsConfig.page = parseInt(this.storedParams.page) || 1;
      }
    },
    async getUsersCore() {
      try {
        this.loading = true;
        const { data, meta } = await getUsers(this.params);
        this.totalPages = meta.totalPages;
        this.users = data;
      } catch (error) {
        this.hasError = error.code !== HTTPStatusCode.Cancel;
      } finally {
        this.loading = false;
      }
    },
    async goToEditUser(payload) {
      const urlName = `${CONTEXT_BOARD_BASE}-edit-user`;
      const params = { id: payload };

      await this.$router.push({
        name: urlName,
        params,
      });
    },

    async goToCreateUser() {
      await this.$router.push({
        name: `${CONTEXT_BOARD_BASE}-new-user`,
      });
    },

    async onCancelModal() {
      await this.$router.push({
        name: `${CONTEXT_BOARD_BASE}-users`,
      });
      await this.queryParamsRouterReplace();
    },
    async onUserSave() {
      await this.$router.push({
        name: `${CONTEXT_BOARD_BASE}-users`,
      });
      await this.filtersChanges();
    },
  },
};
</script>
