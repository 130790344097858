import { ROLES } from '@/model/shared/roles';
import { CONTEXT_BOARD_BASE } from '@/model/shared/constants';

const CONFIG = {
  totalPages: 1,
  itemsPerPage: 7,
  nameRoutes: {
    SOCIAL_AUDIENCE: 'social-audience-new-client',
    THIRD_PARTY: 'third-party-new-client',
    ECOMMERCE: 'ecommerce-new-client',
    RTB: 'rtb-new-client',
  },
  rolesToCreateClient: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.FINANCE.id],
  tabsOptions: {
    WORKSPACE: {
      name: 'Workspaces',
      tab: 'workspace',
      to: `${CONTEXT_BOARD_BASE}-workspaces`,
    },
    USER: {
      name: 'User',
      tab: 'user',
      to: `${CONTEXT_BOARD_BASE}-users`,
    },
  },
  defaultFilters: [
    { id: 'name', name: 'name', value: 'Name', selected: true },
    { id: 'email', name: 'email', value: 'Email', selected: false },
  ],
};

export default CONFIG;
