var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.user
        ? _c(
            "context-card",
            { on: { click: _vm.emitClick } },
            [
              _c("template", { slot: "top-left" }, [
                _c(
                  "h1",
                  {
                    staticClass:
                      "text-gray-900 text-xl leading-none font-semibold text-left truncate w-full",
                    attrs: { title: _vm.user.name },
                  },
                  [_vm._v(" " + _vm._s(_vm.user.name) + " ")]
                ),
              ]),
              _c("template", { slot: "top-right" }, [
                _vm.user.contextRoles.length > 0
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "badge-wrapper flex flex-row justify-end align-center overflow-hidden overflow-y-auto sun-badge-min-width",
                      },
                      [
                        _c(
                          "sun-badge",
                          {
                            staticClass:
                              "px-2 text-xs whitespace-no-wrap truncate",
                            attrs: { color: "blue" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("capitalize")(
                                    _vm.getContextName(
                                      _vm.reversedUserRoles[0].context
                                    )
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _vm.user.contextRoles.length > 1
                          ? [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "badge-circle relative flex items-center rounded-full text-xs bg-blue-300 -ml-2",
                                },
                                [
                                  _vm._v(
                                    " +" +
                                      _vm._s(_vm.user.contextRoles.length - 1) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "context-roles-list absolute p-2 z-10 bg-white border border-gray-300 rounded shadow-md",
                                },
                                _vm._l(
                                  _vm.reversedUserRoles,
                                  function (userRole, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass:
                                          "w-full flex flex-row justify-between items-center py-1",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "px-1 mr-6 text-sm text-gray-900 whitespace-no-wrap",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("capitalize")(
                                                    userRole.role
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "sun-badge",
                                          {
                                            staticClass:
                                              "px-2 text-xs whitespace-no-wrap truncate",
                                            attrs: { color: "blue" },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getContextName(
                                                    userRole.context
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
              ]),
              _c("template", { slot: "bottom-left" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "text-xs md:text-sm text-gray-500 text-left truncate",
                    attrs: { title: _vm.user.email },
                  },
                  [_vm._v(" " + _vm._s(_vm.user.email) + " ")]
                ),
              ]),
              _c("template", { slot: "bottom-right" }, [
                _vm.allowedContextEdit
                  ? _c(
                      "div",
                      { staticClass: "relative" },
                      [
                        _c("asterix-dropdown", {
                          attrs: {
                            options: _vm.actions,
                            "option-list-position": "left",
                          },
                          on: { change: _vm.emitAction },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "icon",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex h-full items-center justify-end p-1",
                                      },
                                      [
                                        _c("menu-dots-svg", {
                                          staticClass: "text-gray-500 h-5",
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            1090316411
                          ),
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }