var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    staticClass:
      "relative h-full animate-pulse px-4 py-3 bg-gray-100 rounded-lg",
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }