var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass:
        "add-card flex flex-row-reverse items-center justify-center w-40 rounded-lg z-10 py-3 fixed h-auto bottom-0 mb-4 sm:mb-0 sm:w-auto sm:z-0 sm:flex-col sm:px-0 sm:relative shadow-md sm:shadow-none",
      on: { click: _vm.emitClick },
    },
    [
      _vm._t("default"),
      _c(
        "span",
        {
          staticClass:
            "rounded-full h-5 w-5 sm:h-7 sm:w-7 md:h-10 md:w-10 flex items-center justify-center px-2 md:px-4 text-center text-white text-2xl sm:text-2xl md:text-4xl leading-none",
        },
        [_vm._v(" + ")]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }