var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full px-4" },
    [
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        !_vm.loading
          ? _c("section", { staticClass: "w-full container m-auto" }, [
              _c(
                "div",
                {
                  staticClass:
                    "grid grid-flow-cards grid-cols-1 gap-1 sm:grid-cols-2 sm:grid-rows-2 sm:gap-4 w-full lg:grid-cols-4 lg:grid-rows-2 lg:gap-4 mt-2",
                },
                [
                  _c(
                    "context-button-add",
                    {
                      class: ["bg-orange-500 hover:bg-orange-400"],
                      on: { click: _vm.emitCreate },
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "text-1xl sm:text-2xl text-white mb-0 md:mb-4 leading-none",
                        },
                        [_vm._v("New user")]
                      ),
                    ]
                  ),
                  _vm.users.length > 0
                    ? _vm._l(_vm.users, function (user) {
                        return _c("context-card-user", {
                          key: user.id,
                          staticClass: "user-single-card mb-4 sm:mb-0",
                          attrs: {
                            user: user,
                            "allowed-context-edit": _vm.allowedContextEdit,
                          },
                          on: {
                            edit: _vm.emitEdit,
                            click: _vm.emitClick,
                            delete: _vm.emitDelete,
                          },
                        })
                      })
                    : [
                        _c(
                          "p",
                          {
                            staticClass:
                              "w-full py-8 text-2xl text-center font-bold leading-tight text-orange-500",
                          },
                          [_vm._v("No users found")]
                        ),
                      ],
                ],
                2
              ),
            ])
          : _c("section", { staticClass: "w-full container m-auto" }, [
              _c(
                "div",
                {
                  staticClass:
                    "grid grid-flow-cards grid-cols-1 gap-1 sm:grid-cols-2 sm:grid-rows-2 sm:gap-4 w-full mt-2 lg:grid-cols-4 lg:grid-rows-2 lg:gap-4",
                },
                _vm._l(8, function (index) {
                  return _c("context-card-loading", {
                    key: index,
                    staticClass: "user-single-card",
                  })
                }),
                1
              ),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }