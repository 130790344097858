<template>
  <context-card @click="emitClick">
    <template slot="top-left">
      <h1 :title="workspace.name" class="text-gray-900 text-xl leading-none font-semibold text-left truncate w-full">
        {{ workspace.name }}
      </h1>
    </template>
    <template slot="top-right">
      <sun-badge
        color="blue"
        class="px-2 text-xs whitespace-no-wrap truncate flex items-center"
        data-testid="top-right"
      >
        {{ contextName }}
      </sun-badge>
    </template>
    <template slot="bottom-left">
      <div class="flex flex-col w-full" data-testid="bottom-left">
        <p
          :title="workspace.contactName"
          class="text-xs md:text-sm text-gray-900 text-left font-semibold truncate w-full"
          v-text="workspace.contactName"
        ></p>
        <p
          :title="workspace.email"
          class="text-xs md:text-sm text-gray-500 text-left truncate w-full"
          v-text="workspace.email"
        ></p>
      </div>
    </template>
    <template slot="bottom-right">
      <div v-if="allowedContextEdit" class="relative" data-testid="bottom-right">
        <asterix-dropdown :options="actions" option-list-position="left" @change="actionClick">
          <template #icon>
            <div class="flex h-full items-center justify-end p-1">
              <menu-dots-svg class="text-gray-500 h-5" />
            </div>
          </template>
        </asterix-dropdown>
      </div>
    </template>
  </context-card>
</template>

<script>
import AsterixDropdown from '@/components/molecules/shared/AsterixDropdown';
import ContextCard from '@/components/atoms/ContextCard/ContextCard';
import { CONTEXTS } from '@/model/shared/contexts';

export default {
  name: 'ContextCardWorkspace',
  components: {
    ContextCard,
    AsterixDropdown,
    MenuDotsSvg: () => import('@/components/icons/MenuDotsSvg'),
  },
  props: {
    workspace: {
      type: Object,
      default: () => {},
    },
    allowedContextEdit: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      seeActions: false,
      actions: [
        { id: 'Edit', name: 'Edit', value: 'edit' },
        { id: 'Delete', name: 'Delete', value: 'delete' },
      ],
    };
  },
  computed: {
    contextName() {
      return CONTEXTS[this.workspace.type].name;
    },
  },
  methods: {
    showActions() {
      this.seeActions = !this.seeActions;
    },
    emitClick() {
      this.$emit('click', this.workspace);
    },
    emitEdit() {
      this.$emit('edit', this.workspace);
      this.showActions();
    },
    emitDelete() {
      this.$emit('delete', this.workspace);
      this.showActions();
    },
    actionClick(payload) {
      if (payload.value === 'edit') {
        this.emitEdit();
      } else {
        this.emitDelete();
      }
    },
  },
};
</script>

<style scoped>
.context-card-actions {
  left: 30px;
  top: 0px;
}
</style>
