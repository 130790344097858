<template>
  <div class="w-full px-4">
    <transition name="fade" mode="out-in">
      <section v-if="!loading" class="w-full container m-auto">
        <div
          class="grid grid-flow-cards grid-cols-1 gap-1 sm:grid-cols-2 sm:grid-rows-2 sm:gap-4 w-full lg:grid-cols-4 lg:grid-rows-2 lg:gap-4 mt-2"
        >
          <context-button-add :class="['bg-orange-500 hover:bg-orange-400']" @click="emitCreate">
            <p class="text-1xl sm:text-2xl text-white mb-0 md:mb-4 leading-none">New user</p>
          </context-button-add>
          <template v-if="users.length > 0">
            <context-card-user
              v-for="user in users"
              :key="user.id"
              class="user-single-card mb-4 sm:mb-0"
              :user="user"
              :allowed-context-edit="allowedContextEdit"
              @edit="emitEdit"
              @click="emitClick"
              @delete="emitDelete"
            />
          </template>
          <template v-else>
            <p class="w-full py-8 text-2xl text-center font-bold leading-tight text-orange-500">No users found</p>
          </template>
        </div>
      </section>
      <section v-else class="w-full container m-auto">
        <div
          class="grid grid-flow-cards grid-cols-1 gap-1 sm:grid-cols-2 sm:grid-rows-2 sm:gap-4 w-full mt-2 lg:grid-cols-4 lg:grid-rows-2 lg:gap-4"
        >
          <context-card-loading v-for="index in 8" :key="index" class="user-single-card"></context-card-loading>
        </div>
      </section>
    </transition>
  </div>
</template>

<script>
import ContextCardUser from '@/components/atoms/ContextCard/user/ContextCardUser';
import ContextButtonAdd from '@/components/atoms/ContextButtonAdd';
import ContextCardLoading from '@/components/atoms/ContextCard/ContextCardLoading.vue';

export default {
  name: 'UserCardList',
  components: {
    ContextCardUser,
    ContextCardLoading,
    ContextButtonAdd,
  },
  props: {
    users: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: () => true,
    },
    allowedContextEdit: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    emitClick(payload) {
      this.$emit('click', payload);
    },
    emitEdit(payload) {
      this.$emit('edit', payload);
    },
    emitCreate() {
      this.$emit('create');
    },
    emitDelete(payload) {
      this.$emit('delete', payload);
    },
  },
};
</script>

<style scoped>
.user-single-card {
  min-height: 8rem;
}
@media screen and (min-width: 640px) {
  .user-single-card {
    min-height: 10rem;
  }
}
</style>
